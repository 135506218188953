@mixin radius($size) {
  @if $size == xs {
    border-radius: 4px;
  }
  @if $size == sm {
    border-radius: 7px;
  }
  @if $size == md {
    border-radius: 20px;
  }
  @if $size == lg {
    border-radius: 50%;
  }
}
@mixin reset-button-defaults {
  outline: none;
  border: 0;
  background: none;
  cursor: pointer;
}
@mixin scrollbar() {
  // Firefox

  scrollbar-color: $color-scrollbar-thumb $color-scrollbar-track;
  scrollbar-width: thin;

  // Webkit

  &::-webkit-scrollbar {
    width: 3px;
    height: 3px;
  }
  /* Track */

  &::-webkit-scrollbar-track {
    background: $color-scrollbar-track;
  }
  /* Handle */

  &::-webkit-scrollbar-thumb {
    background: $color-scrollbar-thumb;
  }
  /* Handle on hover */

  &::-webkit-scrollbar-thumb:hover {
    background: $color-scrollbar-thumb-hover;
  }
}

@mixin dynamicScrollbar($thumb-color) {
  // Firefox

  scrollbar-color: $thumb-color $color-scrollbar-track;
  scrollbar-width: thin;
  cursor: pointer !important;

  // Webkit

  &::-webkit-scrollbar {
    width: 3px;
    height: 3px;
  }
  /* Track */

  &::-webkit-scrollbar-track {
    background: $color-scrollbar-track;
  }
  /* Handle */

  &::-webkit-scrollbar-thumb {
    background: $thumb-color;
    cursor: pointer !important;
  }
  /* Handle on hover */

  &::-webkit-scrollbar-thumb:hover {
    background: darken($thumb-color, 10%);
    cursor: pointer !important;
  }
}
