@import 'palette';

@font-face {
  font-family: Helvetica-Bold;
  src: url('../fonts/Helvetica-Bold.ttf');
}
@font-face {
  font-family: Helvetica;
  src: url('../fonts/Helvetica.ttf');
}
@font-face {
  font-family: Roboto-Regular;
  src: url('../fonts/Roboto-Regular.ttf');
}

@font-face {
  font-family: SFProDisplay;
  src: url('../fonts/SFProDisplay.otf');
}
@font-face {
  font-family: YouRe-Gone;
  src: url('../fonts/YouRe-Gone.ttf');
}
@font-face {
  font-family: MyriadHebrew-Regular;
  src: url('../fonts/Myriad-Hebrew-Regular.otf');
}
@font-face {
  font-family: Metropolis;
  src: url('../fonts/Metropolis-Regular.otf');
  font-weight: 400;
  font-display: swap;
}
@font-face {
  font-family: Metropolis;
  src: url('../fonts/Metropolis-Medium.otf');
  font-weight: 500;
  font-display: swap;
}
@font-face {
  font-family: Metropolis;
  src: url('../fonts/Metropolis-Bold.otf');
  font-weight: 600;
  font-display: swap;
}

:root {
  --font-metropolis: metropolis, sans-serif;
}

// Text size settings

@mixin text-size($size) {
  @if $size == xs {
    font-size: 8px;
  }
  @if $size == sm {
    font-size: 12px;
  }
  @if $size == md {
    font-size: 14px;
  }
  @if $size == lg {
    font-size: 16px;
  }
  @if $size == xl {
    font-size: 18px;
  }
  @if $size == xxl {
    font-size: 20px;
  }
  @if $size == xxxl {
    font-size: 22px;
  }
}

// Headers [h1, h2...]

@mixin heading($type) {
  color: $color-black;
  font-weight: 500;
  font-family: var(--font-metropolis);

  @if $type == 'default' {
    @include text-size(xxl);
  }
  @if $type == 'wide' {
    @include text-size(xxl);

    letter-spacing: 2px;
  }
  @if $type == 'small' {
    @include text-size(xl);
  }
  @if $type == 'extra-small' {
    @include text-size(lg);
  }
}
@mixin textCard($size) {
  font-weight: normal;
  font-family: Roboto-Regular, sans-serif;
  color: $color-gray-dark-3;

  @if $size == xs {
    font-size: 10px;
  }
  @if $size == sm {
    font-size: 13px;
  }
  @if $size == md {
    font-size: 15px;
  }
  @if $size == lg {
    font-size: 17px;
  }
  @if $size == xl {
    font-size: 19px;
  }
  @if $size == xxl {
    font-size: 21px;
  }
  @if $size == xxxl {
    font-size: 26px;
  }
}
@mixin headingCard($type) {
  @include textCard($type);

  font-weight: bold;
  font-family: Roboto-Regular, sans-serif;
  color: $color-gray-dark-3;
}

// Text styles [p, a...]

@mixin text($type) {
  font-weight: 400;
  font-family: Helvetica, sans-serif;

  @if $type == 'default' {
    @include text-size(sm);

    color: $color-text-light;
  }
  @if $type == 'large' {
    @include text-size(sm);

    letter-spacing: 0.7px;
    line-height: 19px;
    color: $color-text-light;

    @include media(md) {
      @include text-size(md);
    }
  }
  @if $type == 'wide' {
    @include text-size(xs);

    letter-spacing: 1.2px;
    color: $color-text-light;

    // @include media(lg) {
    //   @include text-size(sm);
    // }
  }
  @if $type == 'light' {
    @include text-size(md);

    color: $color-text-white;
  }
  @if $type == 'primary' {
    @include text-size(sm);

    color: $color-text-primary;
  }
}

$font-metropolis: Metropolis, sans-serif;
